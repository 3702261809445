import React from "react"
import { Helmet } from "react-helmet"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../styles/style.css"
import Comercio from "../../../static/images/icons/web/icon31.svg"

const Soluciones3 = () => {
  const data = useStaticQuery(graphql`
    query Images_comercio {
      image: allFile(
        filter: { relativeDirectory: { eq: "kit-digital" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Comercio electrónico Kit Digital | Qualoom</title>
        <meta name="title" content="Comercio electrónico Kit Digital | Qualoom"/>
        <meta name="description" content="Soluciones del Kit Digital para la creación de una Tienda Online E-commerce para la compraventa de productos en medios digitales"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/kit-digital/comercio-electronico/"/>
        <link rel="canonical" href="https://www.qualoom.es/kit-digital/comercio-electronico/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/kit-digital/comercio-electronico/"/>
        <meta property="og:title" content="Comercio electrónico Kit Digital | Qualoom"/>
        <meta property="og:description" content="Soluciones del Kit Digital para la creación de una Tienda Online E-commerce para la compraventa de productos en medios digitales"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/kit-digital/comercio-electronico/"/>
        <meta property="twitter:title" content="Comercio electrónico Kit Digital | Qualoom"/>
        <meta property="twitter:description" content="Soluciones del Kit Digital para la creación de una Tienda Online E-commerce para la compraventa de productos en medios digitales"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/kit-digital/comercio-electronico/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Kit Digital", "item": {"@id": "https://www.qualoom.es/kit-digital/", "name": "Kit Digital"}},
              {"@type":"ListItem", "position":"3", "name":"Comercio electrónico", "item": {"@id": "https://www.qualoom.es/kit-digital/comercio-electronico/", "name": "Comercio electrónico"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-kit">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Comercio electrónico</h1>
              <p>Soluciones para la creación de una tienda online para la compraventa en medios digitales</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Comercio electrónico</h2>
          <div className="soluciones-info">
            <img src={Comercio} alt="Comercio electrónico"></img>
            <p><b>Objetivo principal:</b> Creación de una tienda online de compraventa de productos y/o servicios que utilicemedios digitales para su intercambio.</p>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Servicios ofrecidos</p></div>
            <div className="row">
              <div className="normal-cell-1">
                <p><b>Creación de la tienda online o E-Commerce y alta del catálogo de productos:</b> tendrás a tu disposición un catálogo en tu tienda online, en el que estarán disponibles tus productos, mediante su alta, importación o carga. La solución incluye la carga de al menos 100 referencias de tus productos, salvo que no dispongas de este número, en cuyo caso podrá ser menor.</p>
                <p><b>Métodos de pago:</b> el Agente Digitalizador que hayas seleccionado configurará e integrará los métodos de pago en tu tienda online.</p>
                <p><b>Diseño Responsive:</b> tu E-Commerce se podrá utilizar en todo tipo de dispositivos. </p>
                <p><b>Accesibilidad:</b> el diseño de tu tienda online cumplirá con los criterios de conformidad de nivel AA de las Pautas WCAG-2.1. </p>
                <p><b>Posicionamiento básico en internet:</b> tu tienda online será indexable por los principales motores de búsqueda (On-Page).</p>
                <p><b>Autogestionable:</b> contarás también con una plataforma de gestión de contenidos para que puedas modificar los contenidos de tu web sin necesidad de ayuda.</p>
                <p><b>Formas de envío:</b> tendrás configurados e integrados los métodos de envío digital y físico de los productos de tu tienda online.</p>
              </div>
            </div>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Porcentajes de ejecución por fases</p></div>
            <div className="row">
              <div className="normal-cell-2"><p>Primera fase 70%</p></div>
              <div className="normal-cell-2"><p>Segunda fase 30%</p></div>
            </div>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Importe máximo del Bono por segmento</p></div>
            <div className="row">
              <div className="normal-cell-3"><p>Segmento I<br/>2.000€</p></div>
              <div className="normal-cell-3"><p>Segmento II<br/>2.000€</p></div>
              <div className="normal-cell-3"><p>Segmento III<br/>2.000€</p></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Soluciones3
